var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "section basic",
        attrs: { slot: "content" },
        slot: "content",
      },
      [
        _c("span", { staticClass: "title" }, [_vm._v(" 基本信息确认 ")]),
        _c("span", { staticClass: "tips" }, [
          _vm._v("* 为确保后续联系畅通，请认真填写您的信息"),
        ]),
        _c(
          "el-form",
          {
            ref: "basicForm",
            staticClass: "form",
            attrs: {
              "label-position": "right",
              model: _vm.basicForm,
              "label-width": "200px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { display: "none" },
                attrs: { label: "id", prop: "id", required: "" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.basicForm.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.basicForm, "id", $$v)
                    },
                    expression: "basicForm.id",
                  },
                }),
              ],
              1
            ),
            _vm.candidateInfo.isRealNameRequired !== null &&
            _vm.candidateInfo.isRealNameEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "姓名",
                      prop: "name",
                      rules: [
                        {
                          required: _vm.candidateInfo.isRealNameRequired,
                          message: "请输入姓名",
                        },
                        {
                          max: 20,
                          message: "长度小于 20 个字符",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled:
                          !_vm.candidateInfo.isRealNameEditable ||
                          _vm.hasRecognize,
                      },
                      model: {
                        value: _vm.basicForm.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.basicForm,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "basicForm.name",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isMobileRequired !== null &&
            _vm.candidateInfo.isMobileEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "手机号码",
                      prop: "phone",
                      rules: [
                        {
                          validator: _vm.checkPhone,
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: !_vm.candidateInfo.isMobileEditable },
                      model: {
                        value: _vm.basicForm.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "phone", $$v)
                        },
                        expression: "basicForm.phone",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isEmailRequired !== null &&
            _vm.candidateInfo.isEmailEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "邮箱",
                      prop: "email",
                      rules: [
                        {
                          required: _vm.candidateInfo.isEmailRequired,
                          message: "请输入邮箱",
                        },
                        {
                          pattern:
                            /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                          trigger: "blur",
                          message: "请输入正确格式的邮箱",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: !_vm.candidateInfo.isEmailEditable },
                      model: {
                        value: _vm.basicForm.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "email", $$v)
                        },
                        expression: "basicForm.email",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isUniversityRequired !== null &&
            _vm.candidateInfo.isUniversityEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "学校",
                      prop: "university",
                      rules: [
                        {
                          required: _vm.candidateInfo.isUniversityRequired,
                          message: "请输入学校",
                        },
                        {
                          max: 50,
                          message: "长度小于 50 个字符",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled: !_vm.candidateInfo.isUniversityEditable,
                      },
                      model: {
                        value: _vm.basicForm.university,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "university", $$v)
                        },
                        expression: "basicForm.university",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isMajorRequired !== null &&
            _vm.candidateInfo.isMajorEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "专业",
                      prop: "major",
                      rules: [
                        {
                          required: _vm.candidateInfo.isMajorRequired,
                          message: "请输入专业",
                        },
                        {
                          max: 50,
                          message: "长度小于 50 个字符",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: !_vm.candidateInfo.isMajorEditable },
                      model: {
                        value: _vm.basicForm.major,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "major", $$v)
                        },
                        expression: "basicForm.major",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isDegreeRequired !== null &&
            _vm.candidateInfo.isDegreeEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "学历",
                      prop: "degree",
                      rules: [
                        {
                          required: _vm.candidateInfo.isDegreeRequired,
                          message: "请输入学历",
                        },
                        {
                          max: 20,
                          message: "长度小于 20 个字符",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: !_vm.candidateInfo.isDegreeEditable },
                      model: {
                        value: _vm.basicForm.degree,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "degree", $$v)
                        },
                        expression: "basicForm.degree",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isIdCardNumRequired !== null &&
            _vm.candidateInfo.isIdCardNumEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "\n证件类型",
                      prop: "idCardNumber",
                      rules: [
                        {
                          required: _vm.candidateInfo.isIdCardNumRequired,
                          message: "请选择证件类型",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择证件类型" },
                        model: {
                          value: _vm.basicForm.cardType,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicForm, "cardType", $$v)
                          },
                          expression: "basicForm.cardType",
                        },
                      },
                      _vm._l(_vm.cardTypes, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.candidateInfo.isIdCardNumRequired !== null &&
            _vm.candidateInfo.isIdCardNumEditable !== null
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "\n证件号码",
                      prop: "idCardNumber",
                      rules: [
                        {
                          required: _vm.candidateInfo.isIdCardNumRequired,
                          message: "请输入证件号码",
                        },
                        {
                          pattern:
                            // 如果不是身份证就不需要校验
                            _vm.basicForm.cardType === 1
                              ? /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
                              : null,
                          message: "证件号码格式有误！",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled:
                          !_vm.candidateInfo.isIdCardNumEditable ||
                          _vm.hasRecognize,
                      },
                      model: {
                        value: _vm.basicForm.idCardNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.basicForm, "idCardNumber", $$v)
                        },
                        expression: "basicForm.idCardNumber",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "el-form",
          {
            ref: "extraForm",
            staticClass: "form",
            attrs: {
              "label-position": "right",
              model: _vm.extraForm,
              "label-width": "200px",
            },
          },
          _vm._l(_vm.extraForm.extraCandidateInfo, function (item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: {
                  label: item.title,
                  prop: item[index],
                  rules: [{ required: item.idRequired }],
                },
              },
              [
                item.control === 1
                  ? [
                      _c("el-input", {
                        attrs: { disabled: !item.isEdit },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ]
                  : _vm._e(),
                item.control === 5
                  ? [
                      _c("el-input", {
                        attrs: { disabled: !item.isEdit },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", _vm._n($$v))
                          },
                          expression: "item.value",
                        },
                      }),
                    ]
                  : _vm._e(),
                item.control === 4
                  ? [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: !item.isEdit,
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ]
                  : _vm._e(),
                item.control === 2
                  ? [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: !item.isEdit,
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        _vm._l(item.data, function (i, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: i, value: i },
                          })
                        }),
                        1
                      ),
                    ]
                  : _vm._e(),
                item.control === 3
                  ? [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: !item.isEdit },
                          model: {
                            value: item.valueList,
                            callback: function ($$v) {
                              _vm.$set(item, "valueList", $$v)
                            },
                            expression: "item.valueList",
                          },
                        },
                        _vm._l(item.data, function (i, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: i },
                          })
                        }),
                        1
                      ),
                    ]
                  : _vm._e(),
                item.control === 6
                  ? [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          refInFor: true,
                          staticClass: "editor-slide-upload",
                          attrs: {
                            disabled: !item.isEdit,
                            limit: 1,
                            multiple: false,
                            "file-list": _vm.fileList,
                            "show-file-list": true,
                            "before-upload": _vm.beforeUpload,
                            "http-request": () => _vm.uploadHttp(item.control),
                            accept:
                              ".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.webp",
                            action: "",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "danger" } },
                            [_vm._v("点击上传 ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(item.value ? "已上传" : "") +
                              " " +
                              _vm._s(_vm.uploadState) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "operate" },
          [
            this.$store.state.examInfo.isDeviceCheck
              ? _c("span", { staticClass: "prev", on: { click: _vm.prev } }, [
                  _vm._v("上一步"),
                ])
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "submit",
                style: `${
                  this.$store.state.examInfo.isDeviceCheck
                    ? "margin-left:70px;"
                    : "margin-left: 120px;"
                }`,
                attrs: { loading: _vm.isUpdating },
                on: { click: _vm.infoUpdate },
              },
              [_vm._v("保存，下一步")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }