<!--
 * @Author: Libra
 * @Date: 2022-07-08 10:17:24
 * @LastEditTime: 2024-04-09 18:25:11
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/confirm/basic/index.vue
-->
<template>
  <layout>
    <div slot="content" class="section basic">
      <span class="title">
        基本信息确认
      </span>
      <span class="tips">* 为确保后续联系畅通，请认真填写您的信息</span>
      <el-form
        label-position="right"
        class="form"
        ref="basicForm"
        :model="basicForm"
        label-width="200px"
      >
        <el-form-item label="id" prop="id" required style="display: none;">
          <el-input v-model="basicForm.id" />
        </el-form-item>
        <el-form-item
          label="姓名"
          prop="name"
          v-if="
            candidateInfo.isRealNameRequired !== null &&
              candidateInfo.isRealNameEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isRealNameRequired,
              message: '请输入姓名',
            },
            {
              max: 20,
              message: '长度小于 20 个字符',
            },
          ]"
        >
          <el-input
            v-model.trim="basicForm.name"
            :disabled="!candidateInfo.isRealNameEditable || hasRecognize"
          />
        </el-form-item>
        <el-form-item
          label="手机号码"
          prop="phone"
          v-if="
            candidateInfo.isMobileRequired !== null &&
              candidateInfo.isMobileEditable !== null
          "
          :rules="[{
            validator: checkPhone,
            trigger: 'blur',
          }]"
        >
          <el-input
            v-model="basicForm.phone"
            :disabled="!candidateInfo.isMobileEditable"
          />
        </el-form-item>
        <el-form-item
          label="邮箱"
          prop="email"
          v-if="
            candidateInfo.isEmailRequired !== null &&
              candidateInfo.isEmailEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isEmailRequired,
              message: '请输入邮箱',
            },
            {
              pattern: /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
              trigger: 'blur',
              message: '请输入正确格式的邮箱',
            },
          ]"
        >
          <el-input
            v-model="basicForm.email"
            :disabled="!candidateInfo.isEmailEditable"
          />
        </el-form-item>
        <el-form-item
          label="学校"
          prop="university"
          v-if="
            candidateInfo.isUniversityRequired !== null &&
              candidateInfo.isUniversityEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isUniversityRequired,
              message: '请输入学校',
            },
            {
              max: 50,
              message: '长度小于 50 个字符',
            },
          ]"
        >
          <el-input
            v-model="basicForm.university"
            :disabled="!candidateInfo.isUniversityEditable"
          />
        </el-form-item>
        <el-form-item
          label="专业"
          prop="major"
          v-if="
            candidateInfo.isMajorRequired !== null &&
              candidateInfo.isMajorEditable !== null
          "
          :rules="[
            { required: candidateInfo.isMajorRequired, message: '请输入专业' },
            {
              max: 50,
              message: '长度小于 50 个字符',
            },
          ]"
        >
          <el-input
            v-model="basicForm.major"
            :disabled="!candidateInfo.isMajorEditable"
          />
        </el-form-item>
        <el-form-item
          label="学历"
          prop="degree"
          v-if="
            candidateInfo.isDegreeRequired !== null &&
              candidateInfo.isDegreeEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isDegreeRequired,
              message: '请输入学历',
            },
            {
              max: 20,
              message: '长度小于 20 个字符',
            },
          ]"
        >
          <el-input
            v-model="basicForm.degree"
            :disabled="!candidateInfo.isDegreeEditable"
          />
        </el-form-item>
        <el-form-item
          label="
证件类型"
          prop="idCardNumber"
          v-if="
            candidateInfo.isIdCardNumRequired !== null &&
              candidateInfo.isIdCardNumEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isIdCardNumRequired,
              message: '请选择证件类型',
            },
          ]"
        >
          <el-select
            v-model="basicForm.cardType"
            placeholder="请选择证件类型"
            style="width: 100%;"
          >
            <el-option
              v-for="item in cardTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="
证件号码"
          prop="idCardNumber"
          v-if="
            candidateInfo.isIdCardNumRequired !== null &&
              candidateInfo.isIdCardNumEditable !== null
          "
          :rules="[
            {
              required: candidateInfo.isIdCardNumRequired,
              message: '请输入证件号码',
            },
            {
              pattern:
                // 如果不是身份证就不需要校验
                basicForm.cardType === 1
                  ? /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
                  : null,
              message: '证件号码格式有误！',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="basicForm.idCardNumber"
            :disabled="!candidateInfo.isIdCardNumEditable || hasRecognize"
          />
        </el-form-item>
      </el-form>
      <el-form
        label-position="right"
        class="form"
        ref="extraForm"
        :model="extraForm"
        label-width="200px"
      >
        <el-form-item
          :label="item.title"
          v-for="(item, index) in extraForm.extraCandidateInfo"
          :key="index"
          :prop="item[index]"
          :rules="[{ required: item.idRequired }]"
        >
          <template v-if="item.control === 1">
            <el-input v-model="item.value" :disabled="!item.isEdit" />
          </template>
          <template v-if="item.control === 5">
            <el-input v-model.number="item.value" :disabled="!item.isEdit" />
          </template>
          <template v-if="item.control === 4">
            <el-date-picker
              v-model="item.value"
              :disabled="!item.isEdit"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </template>
          <template v-if="item.control === 2">
            <el-select
              v-model="item.value"
              placeholder="请选择"
              :disabled="!item.isEdit"
            >
              <el-option
                v-for="(i, index) in item.data"
                :key="index"
                :label="i"
                :value="i"
              >
              </el-option>
            </el-select>
          </template>
          <template v-if="item.control === 3">
            <el-checkbox-group
              v-model="item.valueList"
              :disabled="!item.isEdit"
            >
              <el-checkbox
                v-for="(i, index) in item.data"
                :key="index"
                :label="i"
              ></el-checkbox>
            </el-checkbox-group>
          </template>
          <template v-if="item.control === 6">
            <el-upload
              ref="upload"
              :disabled="!item.isEdit"
              :limit="1"
              :multiple="false"
              :file-list="fileList"
              :show-file-list="true"
              :before-upload="beforeUpload"
              :http-request="() => uploadHttp(item.control)"
              class="editor-slide-upload"
              accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.webp"
              action=""
            >
              <el-button size="small" type="danger">点击上传 </el-button>
              {{item.value ? '已上传': ''}}
              {{ uploadState }}
            </el-upload>
          </template>
        </el-form-item>
      </el-form>
      <div class="operate">
        <span class="prev" @click="prev" v-if="this.$store.state.examInfo.isDeviceCheck">上一步</span>
      <el-button
class="submit"
:style="`${this.$store.state.examInfo.isDeviceCheck ? 'margin-left:70px;' : 'margin-left: 120px;'}`"
@click="infoUpdate"
:loading="isUpdating"
        >保存，下一步</el-button
      >
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/confirm/layout'
import Api from '@/api/api'

export default {
  name: 'ConfirmBasic',
  components: {
    Layout
  },
  data() {
    return {
      candidateInfo: {
        isDegreeRequired: false,
        isEmailRequired: false,
        isIdCardNumRequired: false,
        isMajorRequired: false,
        isMobileRequired: false,
        isRealNameRequired: false,
        isUniversityRequired: false,
        isDegreeEditable: false,
        isEmailEditable: false,
        isIdCardNumEditable: false,
        isMajorEditable: false,
        isMobileEditable: false,
        isRealNameEditable: false,
        isUniversityEditable: false
      },
      isUpdating: false,
      active: 0,
      basicForm: {
        id: '',
        idCardNumber: '',
        name: '',
        email: '',
        phone: '',
        major: '',
        degree: '',
        university: '',
        cardType: 1
      },
      extraForm: {
        extraCandidateInfo: []
      },
      cardTypes: [
        {
          value: 1,
          label: '身份证'
        },
        {
          value: 2,
          label: '护照'
        },
        {
          value: 3,
          label: '回乡证'
        },
        {
          value: 4,
          label: '台胞证'
        },
        {
          value: 5,
          label: '军官证'
        }
      ],
      context: null,
      video: null,
      isVideo: true,
      mediaStreamTrack: null,
      isDenied: false,
      allowCameraVisible: false,
      isFaceRecognizeNeed: false,
      userInfo: null,
      fileList: [],
      progressPercent: 0,
      path: '',
      uploadState: '',
      // 是否做过人脸识别
      hasRecognize: false
    }
  },
  created() {
    this.examInfo()
    this.info()
    this.getFaceIdResult()
    this.resetIcon()
  },
  methods: {
    prev() {
      this.$router.push('/device')
    },
    checkPhone(rule, value, callback) {
      if (value === '') {
        return callback(new Error('手机号不能为空'))
      } else if (value === null) {
        callback()
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    },
    checkEmail(rule, value, callback) {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      if (mailReg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的邮箱格式'))
      }
    },
    // 获取考试场次信息
    examInfo() {
      const { examInfo } = this.$store.state
      if (this.$route.name !== 'ConfirmBasic') return
      // 第一次进入使用examInfo
      this.extraForm.extraCandidateInfo = examInfo.extraCandidateInfo
      this.candidateInfo = examInfo.candidateInfo
      this.isFaceRecognizeNeed = examInfo.isFaceRecognizeNeed
    },
    // 获取用户信息
    info() {
      const { userInfo } = this.$store.state
      this.userInfo = userInfo
      const { candidateUuid, idCardNum, realName, email, mobile, major, degree, university, idCardType, extraInfo } = userInfo
      this.basicForm = {
        id: candidateUuid,
        idCardNumber: idCardNum,
        name: realName,
        email,
        phone: mobile,
        major,
        degree,
        university,
        cardType: Number(idCardType)
      }
      if (extraInfo.length) {
        this.$set(this.extraForm, 'extraCandidateInfo', extraInfo)
      }
    },
    addFilePath(control, path) {
      const extraFormList = this.extraForm.extraCandidateInfo
      for (const item of extraFormList) {
        if (item.control === control) {
          item.value = path
        }
      }
    },
    filterExtraFormData() {
      const res = []
      const extraFormList = this.extraForm.extraCandidateInfo
      for (const item of extraFormList) {
        if (item.control === 3) {
          if (item.valueList.length === 0) {
            res.push({
              control: item.control,
              data: item.data,
              idRequired: item.idRequired,
              isEdit: item.isEdit,
              title: item.title,
              value: '',
              valueList: item.valueList
            })
          } else {
            res.push({
              control: item.control,
              data: item.data,
              idRequired: item.idRequired,
              isEdit: item.isEdit,
              title: item.title,
              value: item.valueList.join(','),
              valueList: item.valueList
            })
          }
        } else {
          res.push({
            control: item.control,
            data: item.data,
            idRequired: item.idRequired,
            isEdit: item.isEdit,
            title: item.title,
            value: item.value
          })
        }
      }
      return res
    },
    validateExtraForm() {
      const extraFormList = this.extraForm.extraCandidateInfo
      for (const item of extraFormList) {
        if (item.control === 3) {
          if (item.valueList.length === 0 && item.idRequired === true) {
            this.$message.error(`${item.title}为必填项`)
            return false
          }
        } else if (item.control === 5) {
          if (!item.value && item.idRequired === true) {
            this.$message.error(`${item.title}为必填项`)
            return false
          } else if (!Number(item.value) && item.idRequired === true) {
            this.$message.error(`${item.title}必须为数字`)
            return false
          }
        } else {
          if (!item.value && item.idRequired === true) {
            this.$message.error(`${item.title}为必填项`)
            return false
          }
        }
      }
      return true
    },
    async uploadHttp(control) {
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      // 上传新文件时，将进度条值置为零
      this.progressPercent = 0
      // 进度条
      const uploadProgressEvent = (progressEvent) => {
        this.progressPercent = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      }
      await this.getToken()
      let path = ''
      for (const file of this.fileList) {
        const time = new Date().getTime()
        path = `${this.path}/${candidateUuid}_${time}.${file.name
          .split('.')
          .pop()}`
        const filePath = path
        this.uploadState = '正在上传'
        await this.ossUpload(file, file, uploadProgressEvent, filePath)
      }
      if (this.progressPercent === 100) {
        this.addFilePath(control, path)
        this.uploadState = '上传完成'
        this.fileList = []
      }
    },
    // 获取 oss Token
    getToken: async function() {
      const res = await Api.ossToken()
      this.oss = res.data
      this.path = res.data.attachmentPath
      return res
    },
    // 上传阿里云
    ossUpload: async function(blob, file, onUploadProgress, fileName) {
      const data = {
        filename: fileName,
        blob: blob,
        ossInfo: this.oss
      }
      await Api.ossUploadFile(data, onUploadProgress)
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('上传头像文件大小不能超过 10MB!')
        return isLt2M
      }
      this.fileList = [...this.fileList, file]
    },
    // 修改用户信息
    infoUpdate: function() {
      this.$refs.basicForm.validate(async(valid) => {
        if (valid) {
          const va = this.validateExtraForm()
          if (va) {
            this.isUpdating = true
            const ori = this.basicForm
            const data = {
              degree: ori.degree,
              email: ori.email,
              idCardNum: ori.idCardNumber,
              major: ori.major,
              realName: ori.name,
              mobile: ori.phone,
              university: ori.university,
              extraInfo: this.filterExtraFormData(),
              idCardType: ori.cardType
            }
            const r = Object.assign(this.userInfo, data)
            const { commit } = this.$store
            commit('setUserInfo', r)
            const res = await Api.memberInfoUpdate(data)
            const {
              mobileMonitorType,
              cameraMonitorType,
              mobileVideoType
            } = this.$store.state.examInfo
            if (res.code === 0) {
              if (
                mobileMonitorType === 0 &&
                mobileVideoType === 0 &&
                cameraMonitorType === 0
              ) {
                this.$router.push('/notice')
                return
              }
              if (cameraMonitorType !== 0) {
                // 如果不是身份证，则跳转到拍照页面
                this.isFaceRecognizeNeed && this.basicForm.cardType === 1
                  ? this.$router.push('/face')
                  : this.$router.push('/camera')
                return
              }
              if (mobileMonitorType !== 0 || mobileVideoType !== 0) {
                this.$router.push('/mobile')
                return
              }
            } else {
              this.isUpdating = false
            }
          } else {
            this.isUpdating = false
            console.log('error submit!!')
            return false
          }
        } else {
          this.isUpdating = false
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].querySelector('input').focus()
          }, 100)
          return false
        }
      })
    },
    // 去除前后空格
    trim: function(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 获取人脸识别结果
    getFaceIdResult() {
      Api.getFaceIdResult().then((res) => {
        const resData = res.data
        if (resData.faceRecognizeResult === 1) {
          this.hasRecognize = true
        }
      })
    },
    resetIcon() {
      this.$store.commit('setShowCamera', false)
      this.$store.commit('setShowPc', false)
      this.$store.commit('setShowCamera', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/variables.scss";
@import "./index.scss";
</style>
